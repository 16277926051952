<template>
	<div>
		<div class="sub-tit"><p>NCS 직무자가평가</p></div>
		<!-- 자가진단 결과 -->
		<!-- 1set -->
		<div class="duty-wrap">
			<div class="duty-box result" v-for="(dutySelfEvalInfo, dutyIdx) in dutySelfEvalList" :key="dutyIdx" :ref="'CLASS'+dutySelfEvalInfo.detailClassCd">
				<div class="ranking_img" style="margin-bottom: 0;" v-if="dutySelfEvalInfo.ncsGradeCd"><img :src="'/images/tec/prf/ncs_'  + dutySelfEvalInfo.ncsLevelCd.toLowerCase() + dutySelfEvalInfo.ncsGradeCd.toLowerCase() +  '.png'" alt="등급"/></div>
				<div class="tit result" v-if="dutySelfEvalInfo.ncsGradeCd">NCS 직무 자가진단 평가결과</div>
				<div class="intro write mt">
					[{{dutySelfEvalInfo.detailClassNm}} <span v-if="dutySelfEvalInfo.ncsGradeCd">/ {{dutySelfEvalInfo.ncsLevelCd + dutySelfEvalInfo.ncsGradeCd}}</span>]
					<img :src="'/images/tec/prf/' + dutySelfEvalInfo.show + '_bt.png'" :alt="dutySelfEvalInfo.show == 'show' ? '내리기' : '올리기'" @click="dutyShowCng(dutySelfEvalInfo.detailClassCd)"/>
				</div>
				<hr>
				<div class="Board type3" v-if="dutySelfEvalInfo.show == 'hide'">
					<NcsJobTable :ncsJobObj="dutySelfEvalInfo" @move="evalPageMove" @down="ncsDownload" />
				</div>
				<div class="division"></div>
			</div>
		</div>
		
	</div>
</template>

<script>
import NcsJobTable from '@/components/highpro/ncsEval/ncsJobTable.vue';

export default {
	props: {
        tecMberSeq : String  , detailClassCd : String
    },

	data() {
		return {
			data : {},
			
			techData : {},
			dutySelfEvalList : [],

			input : {
				tecMberSeq : 0,
				detailClassCd : '',
			}
		}
	},

	components : {
		NcsJobTable
	},

	beforeMount() {
		//console.log('this.$route.query', this.$route.query);

		if(this.$route.query.tecMberSeq != undefined && this.$route.query.tecMberSeq != '') {
			this.input.tecMberSeq = this.$route.query.tecMberSeq;
		} else {
			this.input.tecMberSeq = this.tecMberSeq;
		}

		this.input.detailClassCd = this.$route.query.detailClassCd;
		
	},

	mounted() {
		this.selectInitData();
	},

	methods : {
		// 기본정보 조회
		selectInitData() {
			this.$.httpPost('/api/mem/prf/selectNcsSelfEvalList', {tecMberSeq : this.input.tecMberSeq, apprYn:'Y'})
				.then(res => {
					//console.log('selectInitData RESULT :', res);

					if(res.data.techData) {
						this.techData = res.data.techData;

						if(res.data.dutySelfEvalList) {
							this.dutySelfEvalList = res.data.dutySelfEvalList;

							for(var i in this.dutySelfEvalList) {
								if(this.dutySelfEvalList[i].detailClassCd == this.input.detailClassCd) this.dutySelfEvalList[i].show = 'hide';
								else this.dutySelfEvalList[i].show = 'show';
							}
						}
						
						//조회 완료 후 포커스 이동.
						var detailClassCd = this.input.detailClassCd;
						this.$nextTick(()=>{
							//console.log('selectInitData focus', detailClassCd, Object.keys(this.$refs));
							if(detailClassCd && this.$refs['CLASS'+detailClassCd]){
								this.$.focus(this.$refs['CLASS'+detailClassCd]);
							} else {
								this.$emit('searchEnd', res.data);
							}
						});
					}
				}).catch(this.$.httpErrorCommon);
		},

		// NCS 직무 숨김처리
		dutyShowCng(dtCd) {
			for(var i in this.dutySelfEvalList) {
				if(this.dutySelfEvalList[i].detailClassCd == dtCd) {
					if(this.dutySelfEvalList[i].show == 'show') this.dutySelfEvalList[i].show = 'hide';
					else this.dutySelfEvalList[i].show = 'show';
					this.$forceUpdate();
				}
			}
		},

		// NCS 자가진단 평가하기/평가보기
		evalPageMove(info, unitIdx) {
			var param = {};
			param.techGradeVerSeq = this.techData.techGradeVerSeq;
			param.mberTechGradeFinalSeq = info.mberTechGradeFinalSeq;
			param.abilUnitCd = info.unitList[unitIdx].abilUnitCd;
			param.techGrade = this.techData.techGrade;
			param.tecMberSeq = this.tecMberSeq;

			this.$.popup('/tec/prf/PRF102P01', param);
			
		},

		// NCS 직무기술서 다운로드
		ncsDownload(filename) {
			this.$.fileDownload('/files/tech/prf/ncs/' + filename + '.pdf', '', '', 'GET');
		},
	}
}
</script>