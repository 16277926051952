<template>
	<span>
		<table :class="tableClass" :style="tableStyle">
			<colgroup>
				<col width="15%">
				<col :width="openType == 'page' ? '49%' : '65%'">
				<col width="10%">
				<col width="10%">
				<col v-if="openType == 'page'" width="16%">
			</colgroup>
			<thead>
				<tr>
					<th>NCS Level</th>
					<th>능력단위명</th>
					<th>문항수</th>
					<th>점수</th>
					<th v-if="openType == 'page'">문항별 평가보기</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(row, idx) in ncsDetailObj.unitList" :key="idx">
					<td class="score" v-if="row.mergeChkCnt == row.mergeCnt" :rowspan="row.mergeChkCnt">{{row.ncsLv}}</td>
					<td>{{row.abilUnitNm}}</td>
					<td class="score">{{row.qeCnt}}</td>
					<td class="score">{{row.unitScore}}</td>
					<td class="score" v-if="openType == 'page'">
						<div class="btn"  @click="$emit('move', ncsDetailObj, idx)" style="background-color:#1C75BC; border:0px;" v-if="row.chkProg == 'do' && mberDivCd == '11'">
							<span style="color:white">평가하기 &#9654;</span>
						</div>
						<div class="btn"  @click="$emit('move', ncsDetailObj, idx)" v-else-if="row.chkProg == 'read'">
							<span>평가보기 &#9654;</span>
						</div>
					</td>
				</tr>
				<tr class="sum2">
					<td colspan="2">합계</td>
					<td class="score">{{ncsDetailObj.quesCnt}}</td>
					<td class="score">{{ncsDetailObj.detailScore}}</td>
					<td class="score" v-if="openType == 'page'"></td>
				</tr>
			</tbody>
		</table>
		<div class="statis">
			<div class="grade">평점 : {{ncsDetailObj.ncsAvg}}</div>
			<div class="ranking">등급 : {{ncsDetailObj.ncsGradeCd || (ncsDetailObj.ncsGrade).toUpperCase()}}</div>
			<img src="/images/tec/prf/duty_download_btn.png" alt="직무기술서다운로드버튼" v-if="openType == 'page' && ncsDetailObj.dutySpecYn == 'Y'" @click="$emit('down', ncsDetailObj.detailClassCd)"/>
		</div>
	</span>
</template>

<script>
export default {
	props : {
		type : String,
		ncsJobObj : {},
	},

	data() {
		return {
			//ncsDetailObj : {},
			openType : this.type || 'page',
			tableClass : 'Board_type3',
			tableStyle : '',

			mberDivCd : this.$store.state.userInfo.mberDivCd

		}
	},

	mounted() {
		if(this.type == 'popup') this.tableStyle = 'border-top:hidden;';
		else this.tableClass += ' ncs-type';
	},

	computed:{
		ncsDetailObj() {
			return this.ncsJobObj || {}
		}
	}
}
</script>